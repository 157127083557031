<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" class="col-sm-6 white">
        <div class="px-4 pt-4 pb-0 pb-sm-4">
          <v-text-field
            v-model="tableSearch"
            outlined
            dense
            hide-details
            class="rounded-0"
            placeholder="Search"
            prepend-inner-icon="mdi-magnify"
          />
        </div>
      </v-col>
      <v-col cols="12" class="col-sm-6 white">
        <div class="pa-4 pl-sm-0">
          <v-select
            v-model="tableSearchStatus"
            :items="[{ name: 'All Status', value: null }, { name: 'No Status', value: 0 }, { name: 'Verified', value: 1 }]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class="rounded-0"
            label="Status"
          />
        </div>
      </v-col>
      <v-col cols="12" class="pt-4">
        <v-data-table
          dense
          :headers="headers"
          :items="table"
          item-key="id"
          :server-items-length="tableTotal"
          :page.sync="tablePage"
          :items-per-page.sync="tableLimit"
          :options.sync="options"
          :footer-props="{
            itemsPerPageOptions: [25, 50, 1000]
          }"
          :loading="tableLoading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.created`]="{ item }">
            <div class="py-2">
              <div>
                {{ $localDT(item.created, 'display') }}
              </div>
            </div>
          </template>
          <template v-slot:[`item.customer_name`]="{ item }">
            <div class="py-2">
              {{ item.customer_name }}
              <div class="caption" style="line-height: 120%;">
                E : {{ item.customer_email }}<br>
                P : {{ item.customer_phone }}
              </div>
            </div>
          </template>
          <template v-slot:[`item.notes`]="{ item }">
            <div>
              {{ item.notes }}
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div>
              {{ !parseInt(item.status) ? 'No Status' : 'Verified' }}
            </div>
          </template>
          <template v-slot:[`item.option`]="{ item }">
            <div style="min-width: 100px;">
              <v-btn v-if="$role(['manager'], $store.state.user.role)" depressed small dark color="orange" class="rounded-0 text-capitalize" @click.prevent="TOUR_GUIDE_SHOW(item)">
                <v-icon small class="mr-2">
                  mdi-information-outline
                </v-icon>
                Detail
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogTourGuide.show"
      persistent
      scrollable
      max-width="340"
    >
      <v-card v-if="dialogTourGuide.data" >
        <v-card-title class="body-1 justify-center">
          {{ dialogTourGuide.data.id ? 'Update Banner' : 'Create New Banner' }}
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-2" style="max-height: 75vh;">
          <a :href="dialogTourGuide.data.selfie || '#'" :class="[dialogTourGuide.data.selfie ? '' : 'disabled']" class="d-block pb-4" style="position: relative;" target="_blank">
            <v-img
              :src="dialogTourGuide.data.selfie"
              aspect-ratio="1"
              class="blue lighten-4 fill-width"
            />
          </a>
          <v-textarea
            v-model="dialogTourGuide.data.notes"
            outlined
            dense
            rows="2"
            hide-details
            label="Status Notes"
            class="mb-4 rounded-0"
          />
          <v-select
            v-model.number="dialogTourGuide.data.status"
            :items="[
              { name: 'No Status', value: 0 },
              { name: 'Verified', value: 1 }
            ]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class="mb-4 rounded-0"
            label="Status"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            class="text-capitalize"
            @click="dialogTourGuide.show = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            class="text-capitalize"
            @click="TOUR_GUIDE_PROCESS()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
let searchInterval = null
export default {
  name: 'TourGuide',
  data: () => ({
    headers: [
      { text: 'Date', value: 'created' },
      { text: 'Customer', value: 'customer_name', sortable: false },
      { text: 'Status Notes', value: 'notes', sortable: false },
      { text: 'Status', value: 'status', align: 'center', sortable: false },
      { text: 'Option', value: 'option', sortable: false }
    ],
    tableSearch: '',
    tableLoading: false,
    tableAchievement: 3,
    table: [],
    tableTotal: 0,
    tablePage: 1,
    tableLimit: 25,
    tableSearchStatus: null,
    options: {},
    dialogTourGuide: {
      show: false,
      data: null
    },
    loadingImageUpload: false
  }),
  computed: {
    ...mapState({
      storeActive: state => state.storeActive
    })
  },
  watch: {
    tableSearch: function (q) {
      if (searchInterval) {
        clearTimeout(searchInterval)
      }
      searchInterval = setTimeout(() => {
        this.TOUR_GUIDE_GET(q)
      }, 300)
    },
    tableSearchStatus () {
      this.TOUR_GUIDE_GET(this.tableSearch)
    },
    storeActive (v) {
      if (v) {
        this.TOUR_GUIDE_GET(this.tableSearch)
      }
    }
  },
  mounted () {
    this.TOUR_GUIDE_GET()
  },
  methods: {
    TOUR_GUIDE_SHOW (banner) {
      if (banner) {
        this.dialogTourGuide.data = Object.assign({}, banner)
      } else {
        this.dialogTourGuide.data = {
          id: null,
          customer: 0,
          selfie: '',
          notes: '',
          status: 0
        }
      }
      this.dialogTourGuide.show = true
    },
    TOUR_GUIDE_GET (q) {
      const status = this.tableSearchStatus === 0 || this.tableSearchStatus > 0 ? this.tableSearchStatus : ''
      this.tableLoading = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const query = '?c=' + ('&store=' + this.$store.state.storeActive) + (parseInt(page) > 0 ? '&page=' + page : '') + (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '') + (sortBy.length ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '')
      this.$store.dispatch('feature/TOUR_GUIDE_GET', query + (q ? ('&q=' + q) : '') + (parseInt(status) === 0 || parseInt(status) > 0 ? '&status=' + status : '')).then((res) => {
        if (res.status) {
          this.table = res.data.data
          this.tableTotal = res.data.data.length
        } else {
          this.table = []
          this.tableTotal = 0
        }
        this.tableLoading = false
      })
    },
    TOUR_GUIDE_PROCESS () {
      const processedBanner = Object.assign({}, this.dialogTourGuide.data)
      this.$store.dispatch('feature/TOUR_GUIDE_PROCESS', processedBanner)
        .then((res) => {
          if (res.status) {
            this.TOUR_GUIDE_GET(this.tableSearch)
            this.dialogTourGuide.show = false
            this.$store.dispatch('TOAST', { show: true, message: processedBanner.id ? 'Berhasil diupdate' : 'Berhasil dibuat' })
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
        })
    }
  }
}
</script>
